/*Application-wide styles*/
.App {
  text-align: center;
  min-height: 100vh;
  background-color: #282828;
  color: white;
  display: block;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;  
}

/* Navbar styles */
.navbar {
  background-color: #4F517D;
}

/*Content body styles*/
main {
  padding-top: 56px;
}
 
h1, h2 {
  padding:15px;
}

li {
  list-style-type: none;
}

ul {
  padding: 0px;
}

hr {
  background-color: white;
  width:60%;
}

.reviewNext h4 {
  padding: 20px;
}

/* Game Header styles */
.header {
  justify-content: space-between;
  display: inline-flex;
  width: 100%;
}

/* Score styles */
.score {
  width: 25%;
  height: 10%;
  background-color: #393e46;
}

.score h4 {
  margin: 5px;
  font-size:min(5vw, 40px);
}

.team1 {
  border-radius: 0px 0px 25px 0px;
}

.team2 {
  border-radius: 0px 0px 0px 25px;
}

/*Timer styles*/
.time {
  font-size: 3rem;
}

.time h3 {
  margin: 0;
  display: inline-block;
  font-size: min(5vw, 40px);
}

.timer {
  width: 40px;
  height: 40px;
  margin-left: 10px;
}

.icon-start{
  background: url(./assets/play-solid.svg) no-repeat center;
}

.icon-pause{
  background: url(./assets/pause-solid.svg) no-repeat center;
}

.icon-reset{
  background: url(./assets/undo-alt-solid.svg) no-repeat center;
}

/*Taboo Card styles*/
.cardDiv {
  width: 600px;
  margin: auto;
  margin-top:10px;
  border-radius: 25px;
  text-align: center;
}

@media (max-width: 750px) {
  .cardDiv {
    width: 80%;
  }
}

.guessWord {
  padding: 10px 20px;
  color: #FFFFFF;
  background: #F26970;
  text-transform: uppercase;
  line-height: 6vh;
  font-size: 5vh;
  margin: 0;
  border-radius: 25px 25px 0px 0px;
}

.tabooWords {
  border: 4px solid #F26970;
  border-radius: 0px 0px 25px 25px;
  margin: 0;
  background: #FFFFFF;
  line-height: 8vh;
  font-size: 3vh;
  display: block;
  padding: 15px 10px;
  color: #333;
  text-transform: capitalize;
}  

/*Button styles*/
.buttonGroup {
  display: inline;
  padding-top:20px;
  padding-bottom:10px;
  width: 600px;
}

@media (max-width: 750px) {
  .buttonGroup {
    width: 80%;
  }
}

.buzzer {
  width: 100%;
  height: 75px;
}

.buttonGroup .btn-success {
  margin-left: 2%;
}

.buttonGroup .btn-danger {
  margin-right: 2%;
}

.actions {
  width:48%;
  height:75px;
}

.icon {
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.icon-success {
  padding-left: 50px;
  background: url(./assets/check-solid.svg) no-repeat center;
}

.icon-danger {
  background: url(./assets/times-solid.svg) no-repeat center;
}

.btn.focus,.btn:focus{
  outline:0;
}

.joinDiv h1 {
  font-size:60px;
}

/*Make Game Styles*/
.joinField, .joinField:focus {
  text-transform: uppercase;
  text-align: center;
  height: 1.5em;
  width: 4em;
  font-size: 80px;
  padding:0.3em;
  border-radius:20px;
  margin-top: 30px;
  outline: none;
}


.nameField, .nameField:focus {
  text-align: center;
  height: 1.5em;
  width: min(7em, 80vw);
  font-size: 80px;
  padding:0.3em;
  border-radius:20px;
  margin-top: 30px;
  outline: none;
}

.mainButton {
  background-color: #4F517D;
  color: white;
  font-size: 40px;
  width: calc(100vw - 20px);
  margin-bottom: 10px;
  display:inline-block;
  font-weight:400;
  text-align:center;
  border:1px solid transparent;
  padding:.375rem .75rem;
  border-radius:.25rem;
}

.footerButtons {
  background-color: #282828;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.newGameDiv h1 {
  font-size: 60px;
}

.newGameDiv h2 {
  font-size: 40px;
}

.newGameDiv p {
 padding: 30px;
}

.players {
  margin:auto;
  border: none;
  display:inline-flex;
  align-content: space-between;
  width:90vw;
}

.players li {
  font-size: 30px;
  padding:0;
  margin:0;
  width:45vw;
  text-align:center;
}
.players h2 {
  width:45vw;
  text-align:center;
}
.teamHeader {
  font-size: min(40px, 10vw);
  font-weight:700;
}